import { render, staticRenderFns } from "./ListBankStatements.vue?vue&type=template&id=8a9016ac"
import script from "./ListBankStatements.vue?vue&type=script&lang=js"
export * from "./ListBankStatements.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QTable from 'quasar/src/components/table/QTable.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPagination from 'quasar/src/components/pagination/QPagination.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QTable,QToolbar,QSpace,QPagination,QBtn});
