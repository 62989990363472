<template>
  <div class="q-mb-sm">
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Faturas</q-tooltip>
      </q-btn>
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered>
      <q-form @submit="onFilterCampaigns">
        <q-toolbar>
          <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
          <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
        </q-toolbar>
        <q-card-section class="q-pt-none row q-col-gutter-md">
          <div class="col-6"> 
            <select-request
              :v-model="params.affiliate"
              outlined
              label="Nome do afiliado"
              endpoint="/redirect?app=AFFILIATE&path=/api/get/affiliate"
              option-label="name"
              option-value="id"
              formatLabelAffiliate
              clearable
              @update="(value) => (params.affiliate = value === null ? null : value.id)"
            />
          </div>

          <div class="col-6">
            <date-input
              buttons
              outlined
              label="Período"
              :v-model="params.paymentDate"
              @update="(value) => (params.paymentDate = value)"
              :rules="[isRequired]"
              clearable
              range
            />
          </div>

          <div class="col-6 q-pt-none">
          <span class="text-weight-medium q-mb-none">Status</span>
          <div class="q-gutter-xs">
            <q-radio v-model="params.status" val="1" keep-color label="Pendente Liberação" class="text-warning" color="warning" />
            <q-radio v-model="params.status" val="2" keep-color label="Liberado" class="text-positive" color="positive" />
            <q-radio v-model="params.status" val="3" keep-color label="Solicitado" class="text-negative" color="negative" />
            <q-radio v-model="params.status" val="5" keep-color label="Pago" class="text-info" color="info" />
          </div>
          </div>

          <div class="col-6 text-right">
            <q-btn color="primary" label="Limpar" no-caps outline class="q-px-md q-mx-md" @click="clearFilter" />
            <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" type="submit" />
          </div>
        </q-card-section>
      </q-form>
    </q-card>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'HeaderApproveInvoices',
  mixins: [FinancialMixin],
  components: { SelectRequest, DateInput },
  data() {
    return {
      filter: true,
      params: {
        affiliate: null,
        paymentDate: null,
        status: null
      }
    };
  },
  methods: {
    clearFilter() {
      for (let i in this.params) this.params[i] = null;

      this.ActionSetFilterInvoicesPayments();
      this.ActionSetInvoicesPayments();
    },

    onFilterCampaigns() {
      let from = this.params.paymentDate.split(' ')[0];
      let to = this.params.paymentDate.split(' ')[2];

      let dateFrom = this.$moment(this.stringToDate(from, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

      let dateTo = this.$moment(this.stringToDate(to, 'dd/MM/yyyy', '/')).format('YYYY-MM-DD');

      this.ActionSetFilterInvoicesPayments({
        affiliateId: this.params.affiliate,
        dateFrom: dateFrom,
        dateTo: dateTo,
        state_id: this.params.status
      });
      this.getStatementsAdmin(dateFrom, dateTo, this.params.affiliate, this.params.status);
    }
  }
};
</script>

