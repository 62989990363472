<template>
  <q-table flat bordered :data="invoicesPayments" :columns="columns" row-key="id" :pagination="pagination">
    <template v-slot:bottom>
      <!-- <pagination-table
              @change="getStatementsAdmin"
              :data="reqPagination"
              :pagination="pagination"
            /> -->
      <q-toolbar class="bg-transparent" :class="!$q.dark.isActive ? 'text-dark' : 'text-white'">
        Total:{{ reqPagination.total }}
        <q-space />
        <q-pagination
          unelevated
          v-model="currentPage"
          color="primary"
          :max="reqPagination.last_page"
          :max-pages="6"
          boundary-numbers
          direction-links
        />
      </q-toolbar>
    </template>
    <template v-slot:top-right>
      <q-btn
        outline
        label="Baixar CSV"
        color="grey-3"
        size="12px"
        text-color="grey-8"
        icon-right="archive"
        class="q-mr-sm q-py-xs"
        no-caps
        @click="exportClickReport()"
        unelevated
      >
      </q-btn>
      <AdminExtract :filters="filterInvoicesPayments" />
    </template>
  </q-table>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import AdminExtract from './AdminExtract';

export default {
  name: 'ListApproveInvoices',
  components: { AdminExtract },
  mixins: [FinancialMixin],

  data() {
    return {
      params: {
        payout: null,
        paymentDay: null
      },
      columns: [
        {
          name: 'affilate_id',
          required: true,
          label: 'ID Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate_idv2',
          required: true,
          label: 'ID v2',
          align: 'left',
          field: 'affiliate_idv2',
          sortable: true
        },
        {
          name: 'affilate',
          required: true,
          label: 'Afiliado',
          align: 'left',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'campaign_id',
          required: true,
          label: 'ID Campanha',
          align: 'left',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Mês/ano Referente',
          align: 'left',
          field: 'date',
          sortable: true
        },
        {
          name: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name',
          sortable: true
        },
        {
          name: 'value',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'value',
          sortable: true
        },
        {
          name: 'state_id',
          required: true,
          label: 'Status',
          field: 'state_id',
          format: (state_id) => this.formatStates(state_id),
          align: 'center'
        }
      ],
      pagination: {
        sortBy: 'trademark',
        descending: true,
        rowsPerPage: 10
      }
    };
  },

  watch: {
    currentPage() {
      if (this.reqPagination.from != this.reqPagination.current_page) {
        this.getStatementsAdmin(
          this.filterInvoicesPayments.dateFrom,
          this.filterInvoicesPayments.dateTo,
          this.filterInvoicesPayments.affiliateId,
          this.filterInvoicesPayments.state_id,
          this.currentPage,
          this.pagination.rowsPerPage
        );
      }
    }
  },

  computed: {
    currentPage: {
      get() {
        return this.reqPagination.current_page;
      },
      set(value) {
        this.ActionSetPagination({
          ...this.reqPagination,
          current_page: value
        });
      }
    }
  },

  methods: {
    formatPaymentDate(date) {
      if (date == null) return 'Sem data definida';
      return (val) => this.$moment(val).format('L');
    },
    async exportClickReport() {
      this.onLoading(true);

      let statementsCsv = await this.getStatementsAdminCsv(
        this.filterInvoicesPayments.dateFrom,
        this.filterInvoicesPayments.dateTo,
        this.filterInvoicesPayments.affiliateId,
        this.filterInvoicesPayments.state_id
      );

      this.exportTableCsv(statementsCsv, this.columns, `admin_extrato_bancario_/`);

      this.onLoading(false);
    },
    formatStates(state) {
      switch (state) {
        case 1:
          return 'Pendente Liberação';

        case 2:
          return 'Liberado';

        case 3:
          return 'Solicitado';

        case 4:
          return 'Aprovado';

        case 5:
          return 'Pago';

        case 11:
          return 'Recusado';

        case 12:
          // return 'Pré-Aprovado';
          return 'Solicitado';

        default:
          return '-';
      }
    }
  }
};
</script>
