<template>
  <q-layout>
    <div class="page-header">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">Extrato Financeiro</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Extrato Financeiro" exact />
      </q-breadcrumbs>
    </div>
    <q-page padding>
      <header-bank-statements></header-bank-statements>
      <list-bank-statements></list-bank-statements>
    </q-page>
  </q-layout>
</template>

<script>
import HeaderBankStatements from '../../components/financial/HeaderBankStatements.vue';
import ListBankStatements from '../../components/financial/ListBankStatements.vue';

export default {
  name: 'PayInvoices',
  components: { HeaderBankStatements, ListBankStatements }
};
</script>
