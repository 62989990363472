<template>
  <div>
    <q-btn
      outline
      label="Baixar Extrato"
      color="grey-3"
      size="12px"
      text-color="grey-8"
      icon-right="archive"
      class="q-mr-sm q-py-xs"
      no-caps
      @click="printPdf()"
      unelevated
    >
    </q-btn>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="extrato_de_transação"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
      ref="html2Pdf"
      @hasPaginated="hasPaginated"
    >
      <section slot="pdf-content">
        <q-card flat class="pdf-item">
          <q-card-section class="row">
            <div class="col-3">
              <q-img :src="require('@/assets/images/logo-afilio-transp.png')" style="width: 150px" />
            </div>
            <div class="col-9">
              <q-item>
                <q-item-section>
                  <q-item-label class="text-h3 text-grey">Extrato</q-item-label>
                  <q-item-label class="text-h5 text-grey"> Bank Statement / Extracto de Cuenta </q-item-label>
                  <q-item-label class="text-grey"> Bank statement date / Fecha de lo extracto </q-item-label>
                </q-item-section>
              </q-item>
            </div>
          </q-card-section>
          <q-card-section class="q-pt-none">
            <q-table
              table-class=".text-caption{12px}"
              flat
              hide-pagination
              bordered
              :data="data"
              :columns="columns"
              :pagination="pagination"
            />
          </q-card-section>
        </q-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import FinancialMixin from '../../mixins/FinancialMixin';

export default {
  name: 'StatementsInvoice',
  components: { VueHtml2pdf },
  mixins: [FinancialMixin],
  props: {
    filters: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      pagination: {
        rowsPerPage: 0
      },
      classPaginate: '',
      columns: [
        {
          name: 'affilate_id',
          required: true,
          label: 'ID Afiliado',
          align: 'left',
          field: 'affiliate_id',
          sortable: true
        },
        {
          name: 'affiliate_idv2',
          required: true,
          label: 'ID v2',
          align: 'left',
          field: 'affiliate_idv2',
          sortable: true
        },
        {
          name: 'affilate',
          required: true,
          label: 'Afiliado',
          align: 'left',
          field: 'affiliate_name',
          sortable: true
        },
        {
          name: 'campaign_id',
          required: true,
          label: 'ID Campanha',
          align: 'left',
          field: 'campaign_id',
          sortable: true
        },
        {
          name: 'campaign_name',
          required: true,
          label: 'Campanha',
          align: 'left',
          field: 'campaign_name',
          sortable: true
        },
        {
          name: 'date',
          required: true,
          label: 'Mês/ano Referente',
          align: 'left',
          field: 'date',
          sortable: true
        },
        {
          name: 'value',
          required: true,
          label: 'Valor da Fatura',
          align: 'left',
          field: 'value',
          sortable: true
        },
        {
          name: 'state_id',
          required: true,
          label: 'Pagamento',
          field: 'state_id',
          format: (state_id) => this.formatStates(state_id),
          align: 'center'
        }
      ],
      data: [],
      affiliatePaymentInfos: {},
      affiliateInfos: {
        name: null,
        last_name: null
      }
    };
  },
  methods: {
    async printPdf() {
      await this.populateTable();

      this.$refs.html2Pdf.generatePdf();
    },

    hasPaginated() {
      this.classPaginate = 'bg-dark';
    },

    formatStates(state) {
      switch (state) {
        case 1:
          return 'Pendente';

        case 2:
          return 'Liberado';

        case 3:
          return 'Solicitado';

        case 4:
          return 'Aprovado';

        case 5:
          return 'Pago';

        case 11:
          return 'Recusado';

        case 12:
          // return 'Pré-Aprovado';
          return 'Solicitado';

        default:
          return '-';
      }
    },

    async populateTable() {
      try {
        const { data, status } = await this.$http.post(`/v2/redirect`, {
          app: 'finance',
          path: `/api/statements_admin/`,
          params: {
            data: {
              page: 1,
              per_page: 9999,
              sort: 'trademark|asc',
              affiliateId: this.filters.affiliateId,
              dataInicio: this.filters.dateFrom,
              dataFim: this.filters.dateTo
            }
          }
        });
        if (status === 200 || status === 201) {
          this.data = data.data;
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
